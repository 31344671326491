//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostSingle from '@/components/Posts/Post'
export default {
    name: 'Post',
    components: {
        PostSingle
    },
    data() {
        return {
            isLoading: true,
            contents: ''
        }
    },
    metaInfo(){
    return{
      title: 'Golfbean',
      titleTemplate: ''
    }
  },
    created() {
        this.$http({
            url: 'posts/' + this.$route.params.id,
            method: 'GET'
        }).then((response) => {
            this.isLoading = false;
            switch (response.data['status']) {
                case 'post_retrieved':
                    this.contents = response.data['body']['post'];
                break;
            }
        }).catch((err) => {
            if (err.response.status == 404 || err.response.status == 403) {
                this.$router.push({name: 'PageNotFound'});
            }
        })
    },
    watch: {
        '$route.params.id' () {
            window.location.reload();
        },
    },
}
